export default {
  buttonGroup: {
    none: 'Izlaz',
    inapp: 'In-App',
    email: 'E-Mail',
  },
  welcome: {
    title: 'Tekst dobrodošlice',
    description: 'Ovo je tekst koji će se pojaviti ispod logotipa.',
  },
  loginLogo: {
    title: 'Logo prijave',
    description: 'Ovdje prenesite logotip za ulaznu stranicu.',
  },
  loginBackground: {
    title: 'Pozadina prijave',
    description: 'Odaberite odgovarajuću pozadinsku sliku za desno područje početne stranice.',
  },
  reportBackground: {
    title: 'Pozadina izvješća',
    description: 'Odaberite prikladnu pozadinsku sliku za svoju stranicu izvješća.',
  },
  uploadTab: {
    title: 'Logotipi i slike',
    description: 'Ovdje prenesite odgovarajuće slike za aplikaciju.',
    confirmDelete: {
      title: 'Izbrisati dateoteku?',
      description: 'Želite li stvarno izbrisati ovu datoteku?',
    },
  },
  reportLogo: {
    title: 'Logo izvješća',
    description: 'Ovdje prenesite logotip za stranicu izvješća.',
  },
  menuLogo: {
    title: 'Logo izbornika',
    description: 'Odaberite odgovarajuću ikonu izbornika.',
  },
  colorTab: {
    title: 'Boja',
    description: 'Ovdje odaberite boje za svoju aplikaciju.',
  },
  primaryColor: {
    title: 'Primarna boja',
    description: 'Odaberite svoju primarnu boju.',
  },
  generalTab: {
    title: 'Opće postavke',
    description: 'Ovdje možete podesiti opće postavke za aplikaciju.',
  },
  modulesTab: {
    title: 'Modul',
    description: 'Ovdje možete definirati koji su moduli aktivni.',
  },
  assignmentsTab: {
    title: 'Dodjela',
    description: 'Ovdje možete definirati dodjele.',
  },
  assignments: {
    title: 'Dodjela',
    description: 'Ovdje možete definirati dodjele.',
    labels: {
      assignment_auto_active: 'AutoDodjela aktivna',
      assignment_type: 'Vrsta dodjele',
      assignment_max_tasks_per_week: ' Maksimalni broj zahtjeva tjedno po korisniku',
      assignment_max_tasks_week_start_day: 'Resetiraj tjedni brojač od dana',
      assignment_max_tasks_max_per_day: 'Maksimalan broj zahtjeva po danu',
    },
  },
  activatePushNotifications: {
    title: 'Aktivirajte automatske obavijesti',
    description: 'Ovdje možete aktivirati slanje poruka na mobilne uređaje.',
    labels: {
      activate: 'Aktivirajte automatske obavijesti',
    },
  },
  configurePushNotifications: {
    title: 'Konfiguracija automatskih obavijesti',
    description:
      'Ovdje kopirajte svoju APNS konfiguraciju kako bi sustav mogao komunicirati s mobilnim uređajima (iOS i Android).',
    labels: {
      configure: 'Konfiguracija aautomatskih obavijesti',
    },
  },
  appTitle: {
    title: 'Naslov platforme',
    description: 'Ovdje možete promijeniti naslov platforme.',
    labels: {
      appTitle: 'Naslov',
    },
  },
  currencies: {
    title: 'Kalkulacija',
    description: 'Ovdje odredite postavke kalkulacije.',
    labels: {
      currencies: 'Valuta',
      tax: 'Porez u %',
    },
  },
  defaultCountry: {
    title: 'Država',
    description: 'Postavite zadanu državu.',
  },
  calendar: {
    title: 'Kalendar',
    description: 'Ovdje definirajte zadane postavke za kalendare',
    labels: {
      show_weekends: 'Prikaži vikend',
      calendar_driving_times: 'Prikaz vremena putovanja',
      driving_times_off: 'Van',
      driving_times_simple: 'Jednostavno',
      driving_times_extended: 'Napredno',
    },
  },
  theme: {
    title: 'Boja teme',
    description: 'Postavite boju teme.',
  },
  mailTab: {
    title: 'Postavke E-Pošte',
    description:
      'Ovdje se mogu konfigurirati sve postavke za slanje e-pošte. Za slanje e-pošte potreban je funkcionalan poslužitelj e-pošte.',
  },
  moduleTab: {
    title: 'Modul',
    description: 'Ovdje možete definirati koji su moduli aktivni',
  },
  modules: {
    title: 'Sigurnosne postavke',
    description: 'Ovdje možete aktivirati vidljivost modula.',
    labels: {
      images: 'Modul slika',
      documents: 'Dokumenti',
      calculations: 'Kalkulacija',
      roomPlan: 'Plan sobe',
      mails: 'E-Pošta',
      customerPortal: 'Portal za korisnike',
      statistics: 'Statistika',
      invoices: 'Računi',
      logs: 'izvještaji',
      subTasks: 'Zadaci',
      additionalForms: 'podobrasci',
      qualifications: 'Kvalifikacije',
    },
    switch: {
      on: 'Uključi',
      off: 'Isključi',
    },
  },
  mail: {
    title: 'SMTP-Server',
    description: 'Ovdje unesite pristupne podatke i adresu vašeg SMTP poslužitelja.',
    labels: {
      port: 'Port',
      host: 'Host',
      username: 'Korisničko ime',
      password: 'Lozinka',
      encryption: 'Enkripcija',
      fromAddress: 'Adresa pošiljatelja',
      fromName: 'Ime pošiljatelja',
      defaultFooter: 'Standardno podnožje',
      prefix: 'prefiks',
      active: 'Aktivirajte isporuku e-pošte',
      mailSendFailureNotice: 'Poslati obavijest o pogrešci?',
    },
    updateSMTPSettingsDialog: {
      title: 'SMTP postavke',
    },
    confirmReset: {
      title: 'Resetiraj SMTP postavke',
      description: 'Želite li stvarno resetirati SMTP postavke?',
    },
  },
  mailSettings: {
    title: 'Postavke e-pošte',
    description: 'Ovdje ćete pronaći opće postavke za otpremu.',
  },
  notifications: {
    title: 'Notifikacije',
    description: 'Odaberite kako korisnici platforme trebaju biti informirani.',
  },
  profileUserSettings: {
    title: 'Korisnički podaci',
  },
  profileImage: {
    title: 'Profilna slika',
    description: 'Učitajte svoju profilnu sliku ovdje',
    confirmDelete: {
      title: 'Izbriši profilnu sliku?',
      description: 'Želite li stvarno izbrisati svoju profilnu sliku??',
    },
  },
  clientNotifications: {
    title: 'Obavijesti',
  },
  changePasswordSelf: {
    title: 'Promjena lozinke',
    description: 'Promijenite lozinku ovdje',
    success: 'Lozinka uspješno promijenjena',
  },
  createApiToken: {
    title: 'API token',
    description: 'Ovdje stvorite novi API token',
    create: 'Generiraj API token',
    copied: 'API token kopiran!',
    labels: {
      createdAtDate: 'Stvoreno dana',
      expiresAtDate: 'Istječe',
      revoked: 'Blokiran',
      uuid: 'Uuid',
    },
    confirm: {
      title: 'Izbriši API token',
      description: 'Trajno izbrisati API token?',
    },
  },
  mapsTab: {
    title: 'Postavke karte',
    description: 'Ovdje možete napraviti postavke za karte u aplikaciji.',
  },
  mapBounds: {
    title: 'Modul karte',
    description: 'Postavite željeni modul karte.',
  },
  dialogs: {
    profileSettings: {
      title: 'Postvake profila',
      description: 'Ovdje možete napraviti postavke svog profila.',
    },
  },
  subnavigation: {
    general: 'Općenito',
    login: 'Stranica za prijavu',
    uploads: 'Logotipi i slike',
    email: 'E-Pošta',
    notification: 'Obavijesti',
    modules: 'Moduli',
    assignments: 'Dodjele',
    maps: 'Karte',
    push: 'Automatske obavijesti',
    tasks: 'procesima',
    invoices: 'računi',
    emailTransport: 'prijenos e-pošte',
    appMessages: 'Vijesti o aplikaciji',
    apiTokens: 'API tokeni',
    appModules: 'Moduli aplikacije',
  },
  pages: {
    platform: {
      title: 'Postavke aplikacije',
      description: 'Ovdje ćete pronaći pregled postavki.',
    },
    classificationTags: {
      title: 'Sistematizacija poslova',
      description:
        'Ovdje možete izraditi vlastite kategorije kako biste svojim zahtjevima dali kategoriju. Također možete filtrirati prema ovim kriterijima.',
      labels: {
        name: 'Ime',
        alias: 'Pseudonim',
        api_only: 'Koristi ga samo API',
        color: 'Boja',
      },
      dialogs: {
        create: {
          title: 'Napravite klasifikaciju',
          note: 'Odaberite ime i boju.',
        },
        delete: {
          title: 'Izbriši klasifikaciju',
          note: 'Želite li stvarno izbrisati klasifikaciju?',
        },
        exports: {
          title: 'Klasifikacija izvoznog naloga',
          note: 'Preuzmite izvezenu klasifikaciju naloga.',
        },
      },
      notifications: {
        create: {
          success: 'Klasifikacija zahtjeva spremljena!',
        },
        delete: {
          success: 'Klasifikacija zahtjeva izbrisana!',
          error: 'Klasifikaciju zahtjeva nije bilo moguće izbrisati jer je još uvijek u upotrebi!',
        },
      },
    },
    fileClassificationTags: {
      title: 'Klasifikacija datoteka',
      description:
        'Ovdje možete stvoriti vlastite kategorije kako biste svojim datotekama dali kategoriju. Također možete filtrirati prema ovim kriterijima.',
      labels: {
        name: 'Ime',
        alias: 'Pseudonim',
        api_only: 'Koristi ga samo API',
        color: 'Boja',
      },
      dialogs: {
        create: {
          title: 'Napravite klasifikaciju',
          note: 'Odaberite ime i boju.',
        },
        delete: {
          title: 'Izbriši klasifikaciju',
          note: 'Želite li stvarno izbrisati klasifikaciju?',
        },
        exports: {
          title: 'Izvoz klasifikacija datoteka',
          note: 'Preuzmite klasifikacije izvezenih datoteka.',
        },
      },
      notifications: {
        create: {
          success: 'Klasifikacija datoteke spremljena!',
        },
        delete: {
          success: 'Klasifikacija datoteke izbrisana!',
          error: 'Klasifikacija datoteke nije se mogla izbrisati jer je još u upotrebi!',
        },
      },
    },
    qualifications: {
      title: 'Kvalifikacije',
      description:
        'Ovdje možete stvoriti vlastite kvalifikacije za dodjelu kategorija svojim narudžbama. Također možete filtrirati prema ovim kriterijima.',
      labels: {
        name: 'Prezime',
        color: 'Boja',
      },
      dialogs: {
        create: {
          title: 'Stvorite kvalifikaciju',
          note: 'Odaberite ime i boju.',
        },
        update: {
          title: 'Uredi kvalifikaciju',
          note: 'Promijenite ime ili boju.',
        },
        delete: {
          title: 'Izbriši kvalifikaciju',
          note: 'Želite li stvarno izbrisati kvalifikaciju?',
        },
        exports: {
          title: 'Izvozne kvalifikacije',
          note: 'Preuzmite izvezene kvalifikacije.',
        },
      },
      notifications: {
        create: {
          success: 'Kvalifikacija sačuvana!',
        },
        delete: {
          success: 'Kvalifikacija izbrisana!',
          error: 'Kvalifikacija se ne može izbrisati jer je još uvijek u upotrebi!',
        },
      },
    },
  },
  userDetails: {
    title: 'Detalji korisnika',
    description: 'Ovdje možete prilagoditi svoje korisničke podatke.',
  },
  userPassword: {
    title: 'Promjena korisničke lozinke',
    description: 'Odaberite sigurnu lozinku.',
  },
  userRole: {
    title: 'Prilagodite korisničku ulogu',
    description: 'Ovdje odaberite svoju korisničku ulogu.',
  },
  emailVerification: {
    title: 'Status',
    description: 'Ovdje pogledajte status svoje potvrde e-pošte',
  },
  invitationLink: {
    title: 'Poveznica za pozivnicu',
    description: 'Ponovno pošaljite vezu pozivnice za korisnika',
  },
  general: {
    notifications: {
      success: 'Uspješno spremljeno.',
      error: 'Žao nam je, došlo je do pogreške.',
    },
  },
  loginPage: {
    title: 'Stranica za prijavu',
    description: 'Ovdje možete definirati detalje za stranicu za prijavu.',
    welcometext: 'tekst dobrodošlice',
    welcometextDesc: 'Ovo je tekst koji će se pojaviti ispod logotipa.',
    imprint: 'otisak',
    conditions: 'Uvjeti',
    privacy: 'zaštita podataka',
  },
  alternativeReportLogo: {
    title: 'Logotip alternativnog izvješća',
    description: 'Ovdje prenesite alternativni logotip za naslovnicu izvješća.',
  },
  invoicesTab: {
    title: 'računi',
    description: 'Ovdje konfigurirajte postavke za generiranje računa.',
    paymentInformation: {
      title: 'Informacije o plaćanju',
      description: 'Ovdje možete unijeti podatke o plaćanju.',
    },
    invoicesOf: {
      title: 'Račun od',
      description: 'informacije o vašoj tvrtki.',
    },
    extended: {
      title: 'Napredna',
      description: 'Više opcija.',
    },
    labels: {
      invoice_from_name: 'Prezime',
      invoice_from_street: 'Ulica',
      invoice_from_postcode: 'Poštanski broj',
      invoice_from_city: 'Grad',
      invoice_from_country_id: 'zemlja',
      invoice_from_phone: 'telefon',
      invoice_from_email: 'e-pošta',
      invoice_from_web: 'mreža',
      invoice_from_bank_title: 'naziv banke',
      invoice_from_bank_iban: 'IBAN',
      invoice_from_bank_swift: 'BIC SWIFT',
      invoice_from_commercial_register: 'matični broj tvrtke',
      invoice_from_ceo: 'generalni direktor',
      invoice_from_vat_title: 'naziv poreznog ureda',
      invoice_from_vat_id: 'PDV ID',
      invoice_from_invoice_number_prefix: 'Skraćenica za broj fakture (npr.: 2023 ili RE)',
    },
  },
  calculationModule: {
    title: 'Postavke izračuna',
    description:
      'Ovdje možete promijeniti vidljivost pojedinih stupaca u izračunu. Promjene utječu na sve buduće narudžbe s izračunima.',
    labels: {
      hideStatusColumn: 'Sakrij stupac "Status".',
      hideAcceptedColumn: 'Sakrij stupac "Prihvaćeno".',
      hidePayoutStateColumn: 'Sakrij stupac "Status plaćanja".',
      calculationDefaultNet: 'Neto izračun kao standard',
    },
  },
  auth: {
    title: 'ovjera',
    description: 'Ovdje možete postaviti razdoblje valjanosti autentifikacije.',
    labels: {
      max_session_duration: 'Dužina sesije / trajanje sesije',
      single_session: 'Možete se prijaviti samo na jednom uređaju',
      standard: 'zadano',
      one_hour: '1 sat',
      two_hours: '2 sata',
      four_hours: '4 sata',
      twofa_active: 'Aktivna je dvosmjerna provjera autentičnosti',
      twofa_sms: 'Tokeni putem SMS-a',
      twofa_email: 'tokene putem e-pošte',
      yes: 'Da',
      no: 'Ne',
    },
  },
  tasks: {
    title: 'procesima',
    description: 'Ovdje konfigurirajte opće procese.',
    labels: {
      tasks_leader_visible_all:
        'Voditelji timova mogu vidjeti sve zadatke, a ne samo one koji su im dodijeljeni.',
      auto_accept_task:
        'Automatsko prihvaćanje naloga - nalog se automatski postavlja na "prihvaćen" od strane regulatora u 22:00 sata.',
      statistic_required_for_controlling:
        'Statistika mora biti popunjena (obavezna polja) prije slanja naloga u Controlling.',
      statistic_required_for_completion:
        'Statistika mora biti popunjena (obavezna polja) prije nego što se narudžba može označiti kao povezana.',
    },
    generalSection: {
      title: 'Opće postavke',
      description: 'Ovdje možete pronaći opće postavke procesa',
      labels: {
        tasks_leader_visible_all:
          'Vođe timova mogu vidjeti sve zadatke (ne samo zadatke koji su im dodijeljeni)',
        auto_accept_task:
          'Automatsko prihvaćanje naloga - nalog se automatski postavlja na "prihvaćen" od strane regulatora u 22:00 sata.',
        statistic_required_for_controlling:
          'Statistika mora biti popunjena (obavezna polja) prije slanja naloga u Controlling.',
        statistic_required_for_completion:
          'Statistika mora biti popunjena (obavezna polja) prije nego što se narudžba može označiti kao izvršena.',
        permissions_delete_files_admin_only: 'Samo administratori mogu brisati datoteke.',
        adjuster_can_create_tasks: 'Platitelj može kreirati naloge',
        hide_drive_log: 'Sakrij unos vremena vožnje u zadacima',
        activate_task_default_off: 'Isključite Izravno otpuštanje posla',
        leader_can_assign: 'Vođa tima može izdavati naredbe',
        msg_convert_single_file: 'Pretvorite Outlook MSG privitke u jednu datoteku',
        everybody_can_see_finished_tasks: 'Svatko može vidjeti izvršene narudžbe',
        create_task_auto_fill_client:
          'Prilikom kreiranja naloga preuzmite kontakt osobu/ugovaratelja iz Bloka Osnovni podaci',
        create_task_auto_fill_client_address:
          'Prilikom kreiranja narudžbe preuzmite mjesto pregleda iz Block Basic Data',
        allow_adjuster_to_send_emails: 'Regulatoru je dopušteno slanje e-pošte',
        adjuster_can_duplicate_own_tasks: 'Regulator može duplicirati vlastite naloge',
        adjuster_can_change_task_location: 'Regulator može prilagoditi mjesto gledanja',
        adjuster_can_set_rework: 'Regulator može dati nalog u naknadnom unosu',
        adjuster_can_send_interface: 'Regulator može slati datoteke putem sučelja',
        disable_task_type_on_desk: 'Onemogući vrstu posla na stolu',
        disable_task_type_video: 'Onemogući video vrste posla',
        log_mails_in_task_log: 'Zapišite e-poštu u datoteku',
        load_predefined_email: 'Automatski učitaj osiguranje u poruke',
      },
    },
    reportSection: {
      title: 'Globalne postavke izvješća',
      description: 'Ovdje možete postaviti globalne postavke za izvješća',
      labels: {
        show_summary_service_provider_block: 'Prikaži blok pružatelja usluga u sažetku',
        global_phone: 'Globalni kontakt telefonski broj',
        global_mail: 'Globalni kontakt email',
        max_export_pages: 'Maksimalan broj PDF stranica koje se izvoze u izvješću.',
        show_adjuster_email_service_provider_block:
          'Prikaži e-poštu regulatora u bloku pružatelja usluga',
      },
    },
    logSection: {
      title: 'Vidljivost i privatnost',
      description: 'Ovdje možete napraviti globalne postavke za vidljivost',
      labels: {
        hide_log_times: 'Sakrij vremena u zapisima',
      },
    },
    tasksSection: {
      title: 'Postavke zadatka',
      description: 'Ovdje možete pronaći opće postavke zadatka',
      labels: {
        tasks_delete_tags_on_complete:
          'Automatski izbrišite klasifikaciju narudžbe nakon završetka.',
        tasks_fields_identifier_active: 'Prikaži broj štete u obrascu',
        tasks_fields_external_number_active: 'Pokaži vanjski broj sustava u obrascu',
        tasks_fields_parallel_number_active: 'Prikaži paralelni/naknadni redoslijed u obrascu',
        tasks_fields_urgent_active: 'Prikaži hitni redoslijed u obrascu',
        tasks_fields_client_contact_id_active: 'Prikaži službenika u obrascu',
        tasks_filter_cancelled: 'Sakrij otkazane narudžbe',
        tasks_hide_duplicate: 'Onemogući značajku Duplicirana narudžba',
      },
    },
  },
  emailTransport: {
    title: 'prijenos e-pošte',
    description: 'Ovdje možete konfigurirati različite metode za prijenos e-pošte.',
    addTransport: 'Dodajte prijenos e-pošte',
    type: 'Tip',
    editTransport: 'Uredi prijevoz',
    deleteTransport: 'Izbriši prijevoz',
    testTransport: 'Pošalji probnu e-poštu',
    labels: {
      title: 'titula',
      type: 'Tip',
      active: 'Aktivan',
    },
    transportTypes: {
      SMTP: 'SMTP',
    },
    dialogs: {
      create: {
        title: 'Dodajte prijenos e-pošte',
        description: 'Ovdje konfigurirajte prijenos e-pošte.',
        success: 'Prijenos e-pošte je kreiran',
      },
      update: {
        title: 'Uredite prijenos e-pošte',
        description: 'Ovdje možete urediti prijenos e-pošte.',
        success: 'Prijenos e-pošte ažuriran',
      },
      delete: {
        title: 'Izbrisati prijenos e-pošte?',
        description: 'Jeste li sigurni da želite izbrisati prijenos e-pošte?',
      },
    },
    smtp: {
      passwordUpdatePlaceholder: 'Ostavite prazno da ne promijenite lozinku.',
      smtpHost: 'domaćin',
      smtpPort: 'luka',
      smtpUsername: 'Korisničko ime',
      smtpPassword: 'lozinka',
      smtpEncryption: 'šifriranje',
      encryptionOptions: {
        SSL: 'SSL',
        TLS: 'TLS',
      },
    },
    notifications: {
      activateSuccess: 'Prijenos e-pošte aktiviran',
      deleteSuccess: 'Prijenos e-pošte izbrisan',
    },
  },
  pushNotificationsTab: {
    title: 'push obavijesti',
    description: 'Konfigurirajte slanje push obavijesti',
  },
  gdv: {
    title: 'GDV web servis',
    description:
      'Ovdje možete aktivirati klasični GDV web servis. Za to vam je potreban poseban klijent kako bi se GDV poruke mogle slati.',
    labels: {
      activate_legacy_gdv: 'GDV web servis',
    },
  },
  appSettingsModule: {
    title: 'Postavke mobilne aplikacije',
    description: 'Ovdje možete odrediti konfiguracije za korištenje mobilnih aplikacija.',
    labels: {
      mobile_app_active: 'Omogući korištenje aplikacije',
      mobile_can_accept: 'Korisnik aplikacije može prihvatiti narudžbe',
      mobile_can_set_appointments: 'Korisnik aplikacije može postaviti sastanke',
      mobile_lock_tasks_when_mobile_active: 'Narudžbe su blokirane prilikom korištenja aplikacije',
    },
  },
  activateEmailDelivery: {
    title: 'Aktivirajte isporuku e-pošte',
    description: 'Ovdje možete aktivirati slanje e-mailova.',
  },
  overviewPage: {
    title: 'postavke',
    description:
      'Postavke - ovdje ćete pronaći sve postavke za sustav. Ispod su neke od najvažnijih postavki za konfiguriranje vašeg sustava.',
    goToLinkTitle: 'Idite na postavke',
    label: {
      settings: 'postavke',
      template: 'Predložak',
    },
  },
  invoices: {
    title: 'Modul fakture',
    description: 'Ovdje možete konfigurirati generiranje računa.',
    labels: {
      invoices: 'računi',
    },
  },
  appMessages: {
    dialogs: {
      create: {
        title: 'stvoriti poruku',
        description: 'Ovdje možete kreirati novu poruku.',
        success: 'Poruka je stvorena.',
      },
      update: {
        title: 'uredi poruku',
        description: 'Ovdje možete urediti poruku',
        success: 'Poruka je promijenjena.',
      },
      delete: {
        title: 'Izbrisati poruku?',
        description: 'Jeste li sigurni da želite izbrisati poruku?',
        success: 'Poruka je izbrisana.',
      },
      changeActiveState: {
        title: 'Promijeniti status?',
        description: 'Jeste li sigurni da želite promijeniti status poruke?',
        success: 'Status je promijenjen.',
      },
    },
    title: 'Vijesti o aplikaciji',
    description:
      'Ovdje možete kreirati globalne poruke koje će biti prikazane svim korisnicima na njihovoj stranici nadzorne ploče.',
    typeOptions: {
      INFO: 'informacija',
      SUCCESS: 'Poruka o uspjehu',
      ERROR: 'Pogreška',
      WARNING: 'upozorenje',
    },
    labels: {
      title: 'titula',
      body: 'Vijesti',
      active: 'Aktivan',
      visible_from: 'Vidljivo iz',
      visible_to: 'Vidljivo do',
      type: 'Tip',
    },
    gridNoRecords: {
      title: 'Nema pronađenih poruka.',
      text: 'Napravi novu poruku sada.',
      create: 'stvoriti poruku',
    },
  },
  devicesModule: {
    title: 'Uređaji',
    description: 'Ovdje možete konfigurirati vidljivost modula uređaja.',
    labels: {
      module_devices_active: 'Uređaji',
    },
  },
  appModules: {
    title: 'Moduli aplikacije',
    description: 'Ovdje možete konfigurirati varijable okoline za svoje module aplikacije.',
    notifications: {
      updateSuccess: 'Modul aplikacije je ažuriran',
      toggleActive: {
        activateSuccess: 'Modul aplikacije je aktiviran',
        deactivateSuccess: 'Modul aplikacije je deaktiviran',
      },
    },
    dialogs: {
      update: {
        title: 'Uredi modul aplikacije',
        description: 'Ovdje možete urediti modul aplikacije.',
      },
    },
    formFields: {
      title: 'titula',
      description: 'Opis',
      active: 'Omogućeno',
      variables: 'varijable',
      value: 'Vrijednost',
      secretHint:
        'Iz sigurnosnih razloga vrijednost se ne prikazuje. Međutim, možete prebrisati vrijednost.',
    },
  },
  password: {
    title: 'lozinka',
    description: 'Ovdje možete postaviti minimalne zahtjeve za lozinku.',
    labels: {
      auth_password_min_length: 'Minimalna duljina',
      auth_password_min_special: 'Broj posebnih znakova',
      auth_password_min_upper_case: 'Broj velikih slova',
      auth_password_min_numbers: 'Broj brojeva',
    },
  },
  autoLogout: {
    title: 'Automatska odjava',
    description: 'Odredite trebaju li se korisnici automatski odjaviti nakon neaktivnosti.',
    labels: {
      auto_logout_timeout: 'Automatska odjava nakon',
    },
  },
};

export const fallbackMapLocation = {
  latitude: 51.337059,
  longitude: 12.324506,
} as const;

export enum MapStyle {
  Monochrome = 'mapbox://styles/mapbox/light-v11',
  Satellite = 'mapbox://styles/mapbox/satellite-v9',
  Streets = 'mapbox://styles/mapbox/streets-v12',
  SatelliteStreets = 'mapbox://styles/mapbox/satellite-streets-v12',
  SatelliteWithRoads = 'mapbox://styles/derdida/cm3zm5c0q001c01s6e7573ogh',

  // this style uses the new standard style design paradigm: https://docs.mapbox.com/mapbox-gl-js/guides/styles/#mapbox-standard-1
  StandardSatelite = 'mapbox://styles/mapbox/standard-satellite',
}
